'use strict'

const SUCCESS_TYPE_NOTIFICATION = 'alert-success';
const ERROR_TYPE_NOTIFICATION = 'alert-danger';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
 function changeHeartIcon(icon) {
    $.spinner().stop();
    if (icon.hasClass('fa-heart-o')) {
        icon.removeClass('fa-heart-o').addClass('fa-heart');
    }
}

/**
 * Create an alert to display the alert message
 * @param {Object} message - Message to display
 * @param {string} notificationType - type of notification to print correct alert color
 */
function displayMessageWishlist(notificationType, message) {    
    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="mt-5 add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="fade show add-to-wishlist-alert text-center ${notificationType}">${message}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
        // button.removeAttr('disabled');
    }, 3000);
}

/**
 * Save preferred list and  shows the selected wishlist
 * @return {void} - Do an Ajax call and manage de response
 */
 function saveAndSelectWishList() {
    $('.list-tab').on('click', function (e) {
        $('.list-tab').removeClass('active');
        $(this).addClass('active');

        var $wishlistTabs = $('.mym-js-wishlist-tabs');
        var url = $wishlistTabs.attr('data-save-list-url');
        var uuid = $(this).attr('data-list-uuid');

        var listUUID = $(this).attr('data-list-uuid');

        $('.wishlist-categories').addClass('d-none');
        $('.wishlist-products').addClass('d-none');

        $('.wishlist-' + listUUID).removeClass('d-none');
        
        if (url) { 
            $.ajax({
                url: url,
                method: 'POST',
                data: { uuid: uuid },
                success: function (data) {
                    if (data.error) {
                        displayMessageWishlist(ERROR_TYPE_NOTIFICATION , data.error);
                    }
                },
                error: function () {
                    displayMessageWishlist(ERROR_TYPE_NOTIFICATION , 'Error Saving preferred wishlist');
                }
            });
        }

    });
}

/**
 * Create a new wishlist
 * @return {void} - Do an Ajax call and manage de response
 */
function createNewList() {
    $('#btn-create-new-wishlist').on('click', function (e) {
        e.preventDefault();

        var closeButton = $('#btn-close-modal-new-wishlist');
        var selectedForm = $('#create-wishlist-form');
        var url = $(selectedForm).attr('action');
        var formData = $(selectedForm).serialize();
        var listElement = $('#createNewWishlistInputValue');
        var listName = $(listElement).val() ? $(listElement).val() : '';

        if (url && listName && listName !== '') {
            $.ajax({
                url: url,
                method: 'post',
                data: formData,
                success: function (response) {
                    $(listElement).html('');
                    // close Modal
                    if ($(closeButton.length > 0)) {
                        $(closeButton).click();
                    }

                    if (response.error) {
                        displayMessageWishlist(ERROR_TYPE_NOTIFICATION , response.message);
                    } else {
                        displayMessageWishlist(SUCCESS_TYPE_NOTIFICATION , response.message);
                        // recarga de la página
                        setTimeout(function () {
                            location.reload();
                        }, 2000);
                    }
                }
            });
        } else if (listName === '') {
            if ($(closeButton.length > 0)) {
                $(closeButton).click();
            }
            displayMessageWishlist(ERROR_TYPE_NOTIFICATION , 'The new whislist needs a name');
        }
    });
}

/**
 * Remove wishlist
 * @return {void} - Do an Ajax call and manage de response
 */
function removeWishList() {
    $('.btn-removelist').on('click', function (e) {
        e.preventDefault();
        var UUID = $(this).data('remove-list-uuid');
        var closeButton = $('#btn-close-modal-remove-wishlist-' + UUID);
        var selectedForm = $('#remove-wishlist-form-' + UUID);
        var url = $(selectedForm).attr('action');
        var formData = $(selectedForm).serialize();

        if (url) {
            $.ajax({
                url: url,
                method: 'post',
                data: formData,
                success: function (response) {
                    // close Modal
                    if ($(closeButton.length > 0)) {
                        $(closeButton).click();
                    }

                    if (response.error) {
                        displayMessageWishlist(ERROR_TYPE_NOTIFICATION , response.message);
                    } else {
                        if ($(closeButton.length > 0)) {
                            $(closeButton).click();
                        }
                        displayMessageWishlist(SUCCESS_TYPE_NOTIFICATION , response.message);
                        setTimeout(function () {
                            location. reload();
                        }, 2000);
                    }
                }
            });
        }
    });
}

/**
 * Edit wishlist
 * @return {void} - Do an Ajax call and manage de response
 */
function editWishList() {
    $('.btn-editlist').on('click', function (e) {
        e.preventDefault();

        var UUID = $(this).data('edit-list-uuid');
        var closeButton = $('#btn-close-modal-edit-wishlist-' + UUID);
        var selectedForm = $('#edit-wishlist-form-' + UUID);
        var url = $(selectedForm).attr('action');
        var formData = $(selectedForm).serialize();

        if (url) {
            $.ajax({
                url: url,
                method: 'post',
                data: formData,
                success: function (response) {
                    // close Modal
                    if ($(closeButton.length > 0)) {
                        $(closeButton).click();
                    }

                    if (response.error) {
                        displayMessageWishlist(ERROR_TYPE_NOTIFICATION , response.message);
                    } else {
                        if ($(closeButton.length > 0)) {
                            $(closeButton).click();
                        }
                        displayMessageWishlist(SUCCESS_TYPE_NOTIFICATION , response.message);
                        setTimeout(function () {
                            location. reload();
                        }, 2000);
                    }
                }
            });
        }
    });
}

/**
 * Shows only the selected dropdown of wishlist if user have more than one
 * @param {string} parentNode - parentNode
 * @return {void} - Do an Ajax call and manage de response
 */
function showHideWishListDropdown(parentNode) {
    // Comportamiento especificon en PDP al actuar sobre dos iconos
    if ($('.wishlist-icon-position-pdp').length) {
        var dropdownLists = $('[class*="dropdown-' + parentNode + '"]', '.detailsPDP, .sticky-add-to-cart');
        // Case click in sticky or PDP product
        if (dropdownLists.length > 0) {

            dropdownLists.each(function() {
                var $element = $(this);
    
                if ($element.hasClass('d-block')) {
                    $element.removeClass('d-block');
                } else {
                    $element.addClass('d-block');
                }
            });
        // Case clik in other PLP -> recomendations
        } else {
            var dropdownList = $('#dropdown-' + parentNode);

            var dropdownLogged = dropdownList.find('.dropdownLogged');
            var dropdownNotLogged = dropdownList.find('.dropdownNotLogged');

            if ($('#customerLogged').data('customerlogged')) {
                dropdownNotLogged.removeClass('d-flex').addClass('d-none');
                dropdownLogged.removeClass('d-none').addClass('d-flex');
            } else {
                dropdownLogged.removeClass('d-flex').addClass('d-none');
                dropdownNotLogged.removeClass('d-none').addClass('d-flex');
            }

            if (dropdownList.hasClass('d-block')) {
                $(dropdownList).removeClass('d-block');
            } else {
                $('.wishlistCustomerList').removeClass('d-block');
                $(dropdownList).addClass('d-block');
            }
        }
    } else {
        // No estamos en la PDP, se mantiene el comportamiento normal
        var dropdownList = $('#dropdown-' + parentNode);

        if (dropdownList.hasClass('d-block')) {
            $(dropdownList).removeClass('d-block');
        } else {
            $('.wishlistCustomerList').removeClass('d-block');
            $(dropdownList).addClass('d-block');
        }
    }
}

/**
 * Add a click event listener for when user clicks outside the heart icon removes any dropdown displayed
 * @return {void} - Do an Ajax call and manage de response
 */
function hideDropdownWhenclickOut() {
    var html = document.body.parentNode;
    html.addEventListener('click', function(event) {
        if (!$('#dropdownMenuLink > span > i.fa').is(event.target)) {
            $('.wishlistCustomerList').removeClass('d-block');
        } else if ($('.wishlistIcon > span > i.fa').is(event.target)) {
            showHideWishListDropdown(event.target.parentNode.getAttribute('data-pid'));
        }
    });
}

/**Adds a product from a selected list
 * @return {void} - Do an Ajax call and manage de response
 */
function addProductToSelectedList(pid, wishlistUUID) {
    var selectedForm = $('#selector-wishlist-form-' + pid + '-' + wishlistUUID);
    var url = $(selectedForm).attr('action');
    var formData = $(selectedForm).serialize();
    if (!url || !pid) {
        return;
    }

    $.ajax({
        url,
        type: 'post',
        dataType: 'json',
        data: formData,
        success(response) {
            displayMessageWishlist(SUCCESS_TYPE_NOTIFICATION , response.message);
            UpdateCustomerWishlistsPseudoContext("Add", pid, wishlistUUID); //MUST DONE before toggle
            toggleProductInFrontLists(pid, wishlistUUID);
            $('.wishlistCustomerList').removeClass('d-block');
        },
        error() {
            displayMessageWishlist(ERROR_TYPE_NOTIFICATION , response.message);
            $('.wishlistCustomerList').removeClass('d-block');
        },
    });
}

/**Removes a product from a selected list
 * @return {void} - Do an Ajax call and manage de response
 */
function handleProductRemoval(pid, wishlistUUID) {
    var selectedForm = $('#selector-wishlist-form-' + pid + '-' + wishlistUUID);
    var url = $(selectedForm).attr('action');
    var formData = $(selectedForm).serialize();

    if (!url || !pid) {
        return;
    }

    $.ajax({
        url,
        type: 'post',
        dataType: 'json',
        data: formData,
        success(response) {
            displayMessageWishlist(SUCCESS_TYPE_NOTIFICATION, response.message);
            UpdateCustomerWishlistsPseudoContext("Remove", pid, wishlistUUID); //MUST DONE before toggle
            toggleProductInFrontLists(pid, wishlistUUID);
            if (window.location.href.includes('wishlist')) {
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        },
        error() {
            displayMessageWishlist(ERROR_TYPE_NOTIFICATION, response.message);
        },
    });
}

/**Redirects to the handleProductRemoval when a product is deleted in "lista de la compra" section
 * @return {void} - Do an Ajax call and manage de response (in "handleProductRemoval")
 */
function removeProductToSelectedList() {
    $('.remove-from-multiwishlist').on('click', function (e) {
        e.preventDefault();
        var pid = $(this).find($('input[name=pid]')).val();
        var wishlistUUID = $(this).find($('input[name=wishlistUUID]')).val();
        handleProductRemoval(pid, wishlistUUID);
    });
}

/**Redirects to "addProductToSelectedList" or "removeProductToSelectedList"
 * @return {void} - Do an Ajax call and manage de response
 */
function SelectorAddOrRemoveFromSelectedList () {
    $(document).off('click', '.wishlistCustomerListLink').on('click', '.wishlistCustomerListLink', function (e) {
        e.preventDefault();
        var pid = $(this).find('input[name=pid]').val();
        var wishlistUUID = $(this).find('input[name=wishlistUUID]').val();
        var customerWishlists = $('#customerLists').data('customerlists');

        var isProductInWishlist = false;

        for (var i = 0; i < customerWishlists.length; i++) {
            if (customerWishlists[i].UUID === wishlistUUID) {
                var wishlist = customerWishlists[i];
                break;
            }
        }

        if (wishlist && wishlist.productsIDs && wishlist.productsIDs.includes(pid)) {
            isProductInWishlist = true;
        }

        if (isProductInWishlist) {
            handleProductRemoval(pid, wishlistUUID);
        } else {
            addProductToSelectedList(pid, wishlistUUID);
        }
    });
}

/**Updates the info in PseudoContext "customerWishlists"
 * @return {void} - Do an Ajax call and manage de response
 */
function UpdateCustomerWishlistsPseudoContext (type, pid, wishlistUUID) {
        var customerWishlists = $('#customerLists').data('customerlists');

        if (customerWishlists) {

            for (var i = 0; i < customerWishlists.length; i++) {
                if (customerWishlists[i].UUID === wishlistUUID) {
                    
                    if (type === "Add" && !customerWishlists[i].productsIDs.includes(pid)) {
                        customerWishlists[i].productsIDs.push(pid);
                    } else {
                        var index = customerWishlists[i].productsIDs.indexOf(pid);
                        if (index !== -1) {
                            customerWishlists[i].productsIDs.splice(index, 1);
                        }
                    }
                }
            }
        }
}

/**Add all product in the list to cart and redirect to cart page
 * @return {void} - Do an Ajax call and manage de response
 */
function addToBasketAllProductsInList() {
    $('.add-all-to-basket').on('click', function (e) {
        e.preventDefault();
        var wishlistUUID = $(this).find($('input[name=wishlistUUID]')).val();
        var selectedForm = $('#add-all-to-basket-wishlist-form-' + wishlistUUID);
        var url = $(selectedForm).attr('action');
        var formData = $(selectedForm).serialize();
        var storefrontUrlCart = 'cart';
        if (!url) {
            return;
        }

        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success() {
                window.location.replace(storefrontUrlCart);
            },
            error() {
                displayMessageWishlist(ERROR_TYPE_NOTIFICATION , 'We cannot add the products to basket');
            },
        });
    });
}

/**
 * Display message and change icon
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
 function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    var status;
    if (data) {
        status = 'alert-success';
        if (icon.hasClass('fa-heart-o')) {
            icon.removeClass('fa-heart-o').addClass('fa-heart');
        }else if (icon.hasClass('fa-heart')) {
            icon.removeClass('fa-heart').addClass('fa-heart-o');
        }
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

/**Add automatic the product when user only has a unic wishlist
 * @return {void} - Do an Ajax call and manage de response
 */
function addToUnicWishlist() {
    $('body').on('click', '.wishlistTileIconUnic', function (e) {
        e.preventDefault();
        var icon = $(this).find('i.fa-heart-o, i.fa-heart').first();
        var url = $(this).attr('href');
        var pid = $(this).attr('data-pid');
        var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
        var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndChangeIcon(data, icon);
            },
            error: function (err) {
                displayMessageAndChangeIcon(err, icon);
            }
        });
    });
}

/**Toggle productId from the wishlist in pseudo CONTEXT generated in frontend
 * @param {string} productID - data returned from the server's ajax call
 * @param {string} wishlistUUID - data returned from the server's ajax call
 * @return {void} - Do an Ajax call and manage de response
 */
function toggleProductInFrontLists(productId, wishlistUUID) {
    var customerWishlists = $('#customerLists').data('customerlists');
    var heartIconElement = $('[data-pid="' + productId + '"] > .fa-stack-1x');
    
    if (customerWishlists && customerWishlists.length > 0) {
        var productExists = false;
        
        for (var i = 0; i < customerWishlists.length; i += 1) {
            var wishlist = customerWishlists[i];
            if (wishlist.productsIDs) {
                if (wishlist.productsIDs && wishlist.productsIDs.includes(productId)) {
                    productExists = true;
                    break;
                }
            }
        }
        if (productExists) {
            heartIconElement.removeClass('fa-heart-o').addClass('fa-heart');
        } else {
            heartIconElement.removeClass('fa-heart').addClass('fa-heart-o');
        }
    }
}

/**Initialise refresh by client proccess
 * @return {void} - Only initialise the wishlist refresh proccess
 */
function initializeWishlists() {
    var customerWishlists = $('#customerLists').data('customerlists');

    // PLP - Actualiza las wishlists en los tiles del PLP
    $('.wishlistIconGeneral').each(function () {
        var productId = $(this).find('.wishlist-icon-position-plp').data('pid');
        actualizarWishlist($(this), productId, customerWishlists);
    });

    // PDP - Actualiza las wishlists en los tiles del PDP
    $('.wishlist-icon-position-pdp').parent().each(function () {
        var productId = $(this).find('.wishlist-icon-position-pdp').data('pid');
        actualizarWishlist($(this), productId, customerWishlists);
    });
}

/**Actualization every wishlist in tile
 * @return {void} - No returns
 */
function actualizarWishlist(element, productId, customerWishlists) {
    var isProductInWishlist = false;

    if (customerWishlists) {
        for (var i = 0; i < customerWishlists.length; i++) {
            var wishlist = customerWishlists[i];
            if (wishlist.productsIDs && wishlist.productsIDs.includes(productId)) {
                isProductInWishlist = true;
                break;
            }
        }
    }

    if (isProductInWishlist) {
        element.find('.fa-stack-1x').removeClass('fa-heart-o').addClass('fa-heart');
    } else {
        element.find('.fa-stack-1x').removeClass('fa-heart').addClass('fa-heart-o');
    }

    // Mostrar u ocultar secciones según si el cliente está logueado
    if ($('#customerLogged').data('customerlogged')) {
        element.find('.dropdownNotLogged').removeClass('d-flex').addClass('d-none');
        element.find('.dropdownLogged').removeClass('d-none').addClass('d-flex');

        // Si está logueado, actualiza las listas desplegables
        if (customerWishlists) {
            var dropdownLogged = element.find('.dropdownLogged');
            var dropdownLoggedContent = $('<div>');
            for (var i = 0; i < customerWishlists.length; i++) {
                var wishlist = customerWishlists[i];
                var wishlistHTML = 
                    `<a class="dropdown-item wishlistCustomerListLink" href="${wishlist.hrefAddProductByUUID}">
                        <form id="selector-wishlist-form-${productId}-${wishlist.UUID}" action="${wishlist.hrefAddProductByUUID}" class="" method="POST" name="selector-wishlist-form-${wishlist.UUID}">
                            <input type="hidden" name="pid" value="${productId}" />
                            <input type="hidden" name="wishlistUUID" value="${wishlist.UUID}" />
                            <input type="hidden" name="${wishlist.tokenName}" value="${wishlist.tokenValue}" />
                        </form>
                        ${wishlist.name ? wishlist.name : 'Lista Nueva'}
                    </a>`;
                var wishlistElement = $(wishlistHTML);
                dropdownLoggedContent.append(wishlistElement);
            }
            dropdownLogged.empty();
            dropdownLogged.append(dropdownLoggedContent);
            dropdownLogged.find('.dropdown-item').unwrap();
            element.find('.dropdownNotLogged').removeClass('d-flex').addClass('d-none');
            dropdownLogged.removeClass('d-none').addClass('d-flex');
        }
    } else {
        element.find('.dropdownLogged').removeClass('d-flex').addClass('d-none');
        element.find('.dropdownNotLogged').removeClass('d-none').addClass('d-flex');
    }
}

$(document).ready(function() {

    initializeWishlists();

    // Vuelve a inicializar wishlists cuando se carguen las recomendaciones
    $(document).on('recommendationsLoaded', function () {
        initializeWishlists();
    });

    SelectorAddOrRemoveFromSelectedList();
});

module.exports = {
    createNewList: createNewList(),
    saveAndSelectWishList: saveAndSelectWishList(),
    removeWishList: removeWishList(),
    editWishList: editWishList(),
    showHideWishListDropdown: showHideWishListDropdown(),
    // addProductToSelectedList: addProductToSelectedList(),
    // handleProductRemoval: handleProductRemoval(),
    removeProductToSelectedList: removeProductToSelectedList(),
    SelectorAddOrRemoveFromSelectedList: SelectorAddOrRemoveFromSelectedList(),
    addToBasketAllProductsInList: addToBasketAllProductsInList(),
    hideDropdownWhenclickOut: hideDropdownWhenclickOut(),
    addToUnicWishlist: addToUnicWishlist()
}
